<script setup lang="ts">
import { updateTalent } from '@/api/mutations/updateTalent';
import { updateTalentDocument } from '@/api/mutations/updateTalentDocument';
import { addTalentDocument } from '@/api/mutations/addTalentDocument';
import { getTalent } from '@/api/queries/talent';
import Button from '@/components/atoms/Button.vue';
import Notification from '@/components/atoms/Notification.vue';
import GoBack from '@/components/molecules/GoBack.vue';
import TalentsUpdateForm from '@/components/organisms/CreateUpdateForm/TalentsUpdateForm.vue';
import strings from '@/data/Talents.json';
import genericString from '@/data/GenericStrings.json';
import { NOTIFICATION_DURATION } from '@/data/constants';
import type {
  MutationUpdateTalentArgs,
  UpdateTalentInput,
  UpdateTalentDocumentInput,
  AddTalentDocumentInput,
} from '@/gql/Gql.types';
import { useGenerateCV } from '@/api/mutations/generateCV';
import { DocumentType } from '@/gql/Gql.types';

import { computed, reactive, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

interface Document {
  documentId?: string;
  file?: object;
  type?: DocumentType;
}

const { talentUpdatedErrorToast, talentUpdatedSuccessToast, talentSubmitLabel } = strings;

const { genericError, updatedSuccessDoc, updatedErrorDoc, addSuccessDoc, addErrorDoc } =
  genericString;

const {
  params: { id },
} = useRoute();

const mutation = useGenerateCV({
  talentId: id as string,
});

const router = useRouter();
const submitted = ref(false);
const manageTalent = ref(false);
const successInference = ref(false);
const notificationPopup = ref(false);
const notesSaved = ref(false);
const biographySaved = ref(false);

const {
  data: dataTalent,
  isError: isErrorFetchingTalent,
  isSuccess: isSuccessTalentLoaded,
  refetch,
} = getTalent({ talentId: id as string });

const headline = computed(() => {
  if (!dataTalent.value) return '';

  const { talent } = dataTalent.value;
  return talent?.firstName + ' ' + talent?.lastName;
});

const datas = computed(
  () => !(!dataTalent.value || !isSuccessTalentLoaded) && dataTalent.value.talent
);

const formData = ref<UpdateTalentInput>();
const data = reactive<MutationUpdateTalentArgs>({
  newTalentData: {} as UpdateTalentInput,
  talentId: id as string,
});

watch(formData, () => {
  data.newTalentData = { ...formData.value };
});

const enabled = computed(() => Object.keys(data.newTalentData).length > 0 && !!id);
const { isSuccess, isError } = updateTalent(data, { enabled });
const updateTalentSubmit = (data: UpdateTalentInput) => (
  (formData.value = data), (submitted.value = false)
);

const document = ref<Document>();

const updateDoc = reactive<UpdateTalentDocumentInput>({
  documentId: '',
  talentId: id as string,
  file: {},
});

const addDoc = reactive<AddTalentDocumentInput>({
  talentId: id as string,
  file: {},
  type: '' as DocumentType,
});

const enabledUpdateDoc = computed(() => Object.keys(updateDoc.documentId).length > 0 && !!id);
const {
  isSuccess: successUpdateDoc,
  isError: errorUpdateDoc,
  remove: removeUpdateDoc,
} = updateTalentDocument(
  { data: updateDoc },
  {
    enabled: enabledUpdateDoc,
  }
);

const enabledAddDoc = computed(() => Object.keys(addDoc.type).length > 0 && !!id);
const {
  isSuccess: successAddDoc,
  isError: errorAddDoc,
  remove: removeAddDoc,
} = addTalentDocument(
  { data: addDoc },
  {
    enabled: enabledAddDoc,
  }
);

const updateTalentDocumentSumbit = (doc: object) => (document.value = { ...doc });

watch(document, () => {
  const { documentId = '', file, type } = document.value || {};
  if (!file) return;

  if (type && type.length > 0) {
    addDoc.file = file;
    addDoc.type = type;
  } else {
    updateDoc.documentId = documentId;
    updateDoc.file = file;
  }
});

watch(isErrorFetchingTalent, () => isErrorFetchingTalent && router.push('/talents'));

const correctMessage = computed(() => {
  switch (true) {
    case errorUpdateDoc.value:
      return updatedErrorDoc;

    case successUpdateDoc.value:
      return updatedSuccessDoc;

    case successAddDoc.value:
      return addSuccessDoc;

    case errorAddDoc.value:
      return addErrorDoc;

    case isSuccess.value:
      return talentUpdatedSuccessToast;

    case isError.value:
      return talentUpdatedErrorToast;

    default:
      return genericError;
  }
});

watch(
  [
    isSuccess,
    successUpdateDoc,
    successAddDoc,
    manageTalent,
    successInference,
    notesSaved,
    biographySaved,
  ],
  async () => {
    if (
      !isSuccess.value &&
      !successUpdateDoc.value &&
      !successAddDoc.value &&
      !manageTalent.value &&
      !successInference.value &&
      !notesSaved.value &&
      !biographySaved.value
    )
      return;
    await refetch();
    if (successUpdateDoc.value) {
      await removeUpdateDoc();
    }
    if (successAddDoc.value) {
      await removeAddDoc();
    }
  }
);

const onSubmit = () => {
  submitted.value = true;

  setTimeout(() => {
    submitted.value = false;
  }, 300);
};

watch([isError, isSuccess, successUpdateDoc, errorUpdateDoc, successAddDoc, errorAddDoc], () => {
  if (
    isError.value ||
    isSuccess.value ||
    errorUpdateDoc.value ||
    errorAddDoc.value ||
    successUpdateDoc.value ||
    successAddDoc.value
  ) {
    notificationPopup.value = true;

    setTimeout(() => {
      updateDoc.documentId = '';
      updateDoc.file = '';

      addDoc.type = '' as DocumentType;
      addDoc.file = {};
      data.newTalentData = {};
    }, NOTIFICATION_DURATION);
  } else {
    notificationPopup.value = false;
  }
});

const onGenerateCV = () => mutation.mutate();
watch([mutation.data], () => {
  if (mutation.data.value?.talentHtmlCV) {
    const parser = new DOMParser();
    const htmlDoc = parser.parseFromString(mutation.data.value?.talentHtmlCV, 'text/html');
    const serializer = new XMLSerializer();
    const htmlString = serializer.serializeToString(htmlDoc);

    const cvWindow = window.open(
      '',
      '_blank',
      'directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,width=850,height=1000'
    );
    cvWindow?.document.write(
      `<title>CV - ${dataTalent.value?.talent?.firstName} ${dataTalent.value?.talent?.lastName}</title>` +
        htmlString
    );
  }
});
</script>
<template>
  <div class="UpdateTalent">
    <GoBack :headline="headline" previous-route="/talents" />
    <div class="UpdateTalent__submit">
      <Button variant="secondary" label="Generate CV" @clicked="onGenerateCV" />
      <Button :label="talentSubmitLabel" @clicked="onSubmit" />
    </div>
    <div class="UpdateTalent__form container">
      <div class="col-12">
        <TalentsUpdateForm
          v-if="datas"
          v-bind="{ ...datas }"
          :submitted="submitted"
          @talent-data="updateTalentSubmit"
          @update-document="updateTalentDocumentSumbit"
          @manage-talent="(action: boolean) => manageTalent = action"
          @success-inference="(inference: boolean) => successInference = inference"
          @saved-notes="(notes: boolean) => notesSaved = notes"
          @saved-biography="(biography: boolean) => biographySaved = biography"
        />
      </div>
    </div>
    <Notification
      v-if="notificationPopup"
      :message="correctMessage"
      :status="isError || errorUpdateDoc || errorAddDoc ? 'error' : 'success'"
      :duration="NOTIFICATION_DURATION"
    />
  </div>
</template>
<style scoped lang="scss">
.UpdateTalent {
  position: relative;

  &__form {
    margin-top: 4rem;
  }

  &__submit {
    position: absolute;
    right: 4rem;
    top: 4rem;
    display: flex;
    gap: 2rem;
  }
}
</style>
